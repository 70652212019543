import axios from "axios";

// Set config defaults url
const { NODE_ENV: ENV } = process.env;

export const API =
	ENV === "production"
		? axios.create({
				baseURL: ' https://flowently-booking.whellostudio.id/api/'
				// baseURL: "https://admin.flowently.com/api/",
		  })
		: axios.create({
				baseURL: ' https://flowently-booking.whellostudio.id/api/'
				// baseURL: "http://localhost:3001/",
		  });

export const DOMAIN = "https://flowently.com";

export const BOOKING_DOMAIN =
	ENV === "production"
		// ? "https://booking.flowently.com"
		// : "http://localhost:3000";
		? 'https://flowently-booking.whellostudio.id'
		: 'https://flowently-booking.whellostudio.id'
		  
